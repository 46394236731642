import React, { useState } from "react";
import { MessageHelper, NotificationType } from "gl-commonui";
import { DatePicker } from "antd-min";
import { Action, Actions } from "@app/components";
import { SurveyLocale, } from "@app/locales/localeid";
import { ISurveyService } from "@app/service/survey";
import { BlobHelper, TYPES, fmtMsg } from "@app/util";
import "./created-polls.less";
import { useService } from "@app/hooks";
import moment from "moment";
const { RangePicker } = DatePicker;

export const ExitSurveyDownload = () => {
    const surveyService = useService<ISurveyService>(TYPES.ISurveyService);
    const [startDate, setStartDate] = useState(moment().subtract(365, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [ isDateRangeValid, setIsDateRangeValid ] = useState(true);

    const exportExitSurveyData = (isDateRangeValid) => {
        if(!isDateRangeValid) {
            MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: SurveyLocale.ExitSurveyDownloadException}));
            return;
        }

        surveyService.downloadExitSurveyData({ startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') })
            .then(data => {
                if (BlobHelper.saveFileSurvey(data)) {
                    MessageHelper.Message(NotificationType.Success, fmtMsg({ id: SurveyLocale.SurveyExportMessageSuccess }));
                }
            })
            .catch((error) => {displayErrorMessage(error)})
    }

    const displayErrorMessage = (error) => {
        let errorStatusCode = error.statusCode;
        switch(errorStatusCode){
            case 400: 
                MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: SurveyLocale.ExitSurveyDownloadDateRangeException}));
                break;
            default: 
                MessageHelper.Message(NotificationType.Warning, fmtMsg({ id: SurveyLocale.ExitSurveyDownloadException}));
        }
    } 

    const onDateChange = (dates) => {
        const [start, end] = dates;
        (!start || !end) ? setIsDateRangeValid(false) : setIsDateRangeValid(true);
        setStartDate(start);
        setEndDate(end);
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }
    
    return (
        <div className="exit-survey-container">
            <RangePicker className="date-range" value={[startDate, endDate]} onChange={onDateChange} disabledDate={disabledDate}></RangePicker>
            <Actions
                className="download-action-button"
                noHeight
                actions={[
                    <Action
                        textProps={{ underline: true }}
                        key="0"
                        onClick={() => exportExitSurveyData(isDateRangeValid)}
                        materialIcon="download"
                        textLocaleId={SurveyLocale.ExitSurveyDownloadRecords}
                        disabled={!isDateRangeValid}
                    />
                ]}
            />
        </div>
    )
}