import { Action, Container, PageHeader } from "@app/components/survey";
import { SurveyPathConfig } from "@app/config/pathconfig";
import { SurveyLocale } from "@app/locales/localeid";
import { SurveyAction, fmtMsg, CONSTS, PollSectionKeys, ContextHelper } from "@app/util";
import { GLRouteComponentProps, RoleName, withRouter } from "gl-commonui";
import React from "react";
import { CreatedPolls } from "./components/created-polls/created-polls";
import { usePermissions, PermissionType } from "@app/hooks/usePermissions";
import { ReviewPolls } from "./components/review-polls";
import { ExitSurveyDownload } from "./components/created-polls/exit-survey-download";

interface PollsPageProps extends GLRouteComponentProps {
    permissions: string[];
}

export const PollsPage = withRouter((props: PollsPageProps) => {
    const permissions = usePermissions(PermissionType.Poll);
    const { history } = props;

    const { PollPermissionMap } = CONSTS;

    const allowedRoles = [RoleName.systemAdmin.toString(), RoleName.globalHead.toString()];
    const userInfo = ContextHelper.getUserLoginInfo();
    const roles = userInfo && userInfo.profile && userInfo.profile.roleInfos ? userInfo.profile.roleInfos : null;
    const isAllowed = roles.some(r => allowedRoles.includes(r.name));

    const onCreatePollClick = () => {
        history.push(`${SurveyPathConfig.Create}`);
    };

    const hasCreatePermission = permissions.some(p => p === SurveyAction.CreateSurvey);

    return (
        <Container fullWidth>
            <PageHeader title={fmtMsg({ id: SurveyLocale.PollHeading })} actions={hasCreatePermission && [<Action textProps={{ underline: true }} key="0" onClick={onCreatePollClick} materialIcon="add" textLocaleId={SurveyLocale.CreateNewPoll} />]} />
            {isAllowed && <ExitSurveyDownload />}
            {permissions.some(p => PollPermissionMap[PollSectionKeys.View].some(viewPerm => viewPerm == p)) && <CreatedPolls />}
            {permissions.some(p => PollPermissionMap[PollSectionKeys.Review].some(reviewPerm => reviewPerm == p)) && <ReviewPolls history={history} />}
        </Container>
    );
});
